import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["time"];

  connect() {
    var tenMinutes = 60 * 10,
      display = this.timeTarget;
    this.startTimer(tenMinutes, display);
  }

  startTimer(duration, display) {
    var timer = duration,
      minutes,
      seconds;
    setInterval(function () {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      display.textContent = minutes + ":" + seconds;

      if (--timer < 0) {
        timer = duration;
      }
    }, 1000);
  }
}
