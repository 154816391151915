import { Controller } from "@hotwired/stimulus";
// import { enableElement } from "@rails/ujs";

export default class extends Controller {
  static targets = ["errors"];

  disconnect() {
    try {
      this.errorsTarget.remove();
    } catch (err) {}
  }

  reset() {
    this.element.reset(); // reset form contents
    this.element
      .querySelectorAll("[type=submit],input[type=file]")
      .forEach((e) => {
        // reset data-disable-with
        // reset direct upload file inputs (active storage disables these on submit)
        $.rails.enableElement(e);
        e.disabled = false;
      });
  }
}
