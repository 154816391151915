import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["time", "button"];
  static values = { timeLeft: Number };

  connect() {
    var display = this.timeTarget;
    this.startTimer(this.timeLeftValue, display, this.buttonTarget);
  }

  startTimer(duration, display, button) {
    var timer = duration,
      minutes,
      seconds;
    var interVal = setInterval(function () {
      if (--timer > 1) {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        display.textContent = minutes + ":" + seconds;
      } else {
        button.setAttribute("aria-disabled", false);
        button.textContent = "Solicitar código nuevo";
        clearInterval(interVal);
      }
    }, 1000);
  }
}
