// import { enableElement, disableElement } from "@rails/ujs";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "card", "errors", "stripeToken", "button"];
  static values = {
    clientSecret: String,
    confirmUrl: String,
    publicKey: String,
  };

  connect() {
    this.stripe = Stripe(this.publicKeyValue, {
      locale: "es",
    });

    this.elements = this.stripe.elements({
      clientSecret: this.clientSecretValue,
      fonts: [
        {
          // integrate your font into stripe
          family: "Gilroy",
          weight: "500",
          cssSrc: "https://www.ahorasoy.com/fonts/Gilroy.css",
        },
      ],
    });

    this.card = this.elements.create("payment");
    this.card.mount(this.cardTarget);
  }

  change(event) {
    if (event.error) {
      this.errorsTarget.textContent = event.error.message;
    } else {
      this.errorsTarget.textContent = "";
    }
  }

  stripeTokenHandler(token) {
    this.stripeTokenTarget.value = token.id;
    this.element.submit();
  }

  submit(event) {
    event.preventDefault();
    $.rails.disableElement(this.element);

    var elements = this.elements;

    this.stripe
      .confirmSetup({
        elements,
        confirmParams: {
          return_url: this.confirmUrlValue,
        },
      })
      .then((result) => {
        if (result.error) {
          $.rails.enableElement(this.element);
        } else {
          this.stripeTokenHandler(result.paymentMethod);
        }
      });
  }
}
