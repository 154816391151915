import { Controller } from "@hotwired/stimulus";
import intlTelInput from "intl-tel-input";
import utils from "intl-tel-input/build/js/utils.js";

export default class extends Controller {
  static targets = ["number", "localNumber", "countryFlag"];
  static values = ["iti"];

  connect() {
    this.localNumberTarget.value = this.numberTarget.value;
    const input = this.localNumberTarget;
    this.itiValue = intlTelInput(input, {
      initialCountry: this.countryFlagTarget.value || "uy",
      utilsScript: utils,
      autoPlaceholder: "polite",
    });
  }

  submit() {
    this.numberTarget.value = this.itiValue.getNumber();
    this.countryFlagTarget.value = this.itiValue.getSelectedCountryData().iso2;
  }
}
